@import url('https://fonts.googleapis.com/css?family=Manrope:300,400,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=DM+Serif+Text:400,500,600');
@import url('https://fonts.googleapis.com/css?family=DM+Sans:100,200,300,400,500,600,700,800,900');

body {
  /* -------------- COLOR -------------- */
  --color-primary: #d6d6d6;
  --color-text-primary: #e6e6e6;
  --color-background-primary: #191919;
  --color-everlaw: #61c41b;
  --color-wefunder: rgb(0, 155, 233);
  --color-festiplannr: rgb(179, 0, 233);

  /* ------------ TYPOGRAPHY ------------ */
  --ff-heading: 'DM Serif Text', serif;
  --ff-default: 'Manrope', sans-serif;

  margin: 0;
  padding: 0;
  color: var(--color-text-primary);
  font-family: var(--ff-default);
  background: var(--color-background-primary);
  overflow-x: hidden;
}

h1 {
  font-family: var(--ff-default);
  font-size: 36px;
  font-weight: 900;
  line-height: 36px;
  margin: 0;
  color: var(--color-text-primary);
  letter-spacing: 1px;
  text-transform: uppercase;
}
h2 {
  font-family: var(--ff-default);
  font-size: 22px;
  font-weight: 900;
  line-height: 32px;
  margin: 0;
  color: var(--color-text-primary);
}
h3 {
  font-family: var(--ff-default);
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
  color: var(--color-text-primary);
  opacity: 1;
}
p {
  font-family: var(--ff-default);
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  color: var(--color-text-primary);
}

.link {
  color: var(--color-primary);
  text-decoration: none;
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
  transition: 0.3s all ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.u-title.link {
  padding-bottom: 4px;
}
.link.link-wefunder:hover {
  color: var(--color-wefunder);
  border-bottom: 1px solid var(--color-wefunder);
}
.link.link-everlaw:hover {
  color: var(--color-everlaw);
  border-bottom: 1px solid var(--color-everlaw);
}
.link.link-festiplannr:hover {
  color: var(--color-festiplannr);
  border-bottom: 1px solid var(--color-festiplannr);
}
.link.link-berkeley:hover {
  color: #f2aa1c;
  border-bottom: 1px solid #f2aa1c;
}
.link.link-upenn:hover {
  color: #cd2013;
  border-bottom: 1px solid #cd2013;
}
.link.link-penguins:hover {
  color: #00e6de;
  border-bottom: 1px solid #00e6de;
}
.link:active {
  opacity: 0.4;
}
.icon-link {
  color: var(--color-primary);
  cursor: pointer;
  transition: 0.3s all ease;
}
.icon-link.link-festiplannr {
  color: rgba(0, 133, 255, 1);
  opacity: 1;
  transform-origin: top left;
}
.icon-link.link-everlaw {
  color: var(--color-everlaw);
  opacity: 1;
  transform-origin: top left;
}
.icon-link:active {
  opacity: 0.3;
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 36px;
    line-height: 40px;
  }
  h2 {
    font-size: 20px;
  }
}
