.u-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.u-new-section {
  margin-top: 160px;
}
.u-first-section {
  padding-top: 32px;
}
.u-header-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
}
.u-title-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}
.u-title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.5px;
}
.u-title-divider {
  width: 80px;
  height: 1px;
  opacity: 0.35;
}
.u-title-left {
  background: linear-gradient(
    90deg,
    var(--color-background-primary) 0%,
    var(--color-text-primary) 100%
  );
}
.u-title-right {
  background: linear-gradient(
    -90deg,
    var(--color-background-primary) 0%,
    var(--color-text-primary) 100%
  );
}
.u-subtitle {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  opacity: 50%;
  font-weight: 400;
}
.u-desc {
  max-width: 480px;
  font-size: 16px;
  line-height: 26px;
  opacity: 85%;
  padding: 0 32px;
}

.u-videos {
  display: flex;
  gap: 16px;
  width: calc(100% - 32px);
  margin: 0 16px;
  overflow: clip;
  max-width: 1728px;
  margin-bottom: 18px;
}
.u-double-video {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  width: calc(50% - 8px);
}
.u-video {
  width: calc(100% - 32px);
  margin: 0 16px;
  border-radius: 24px;
  overflow: clip;
  max-width: 1728px;
  height: auto;
  object-fit: cover;
  margin-bottom: 18px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.4);
}

.u-imgsets {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
  width: calc(100% - 32px);
  margin: 0 16px;
  max-width: 1728px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.u-imgset {
  display: flex;
  gap: 8px;
}
.u-img {
  border-radius: 6px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.4);
}

.u-testimonials {
  width: calc(100% - 32px);
  margin: 0 16px;
  max-width: 1728px;
  margin-top: 18px;
  padding-top: 22px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.testimonial-item {
  width: 160px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.4);
  text-align: center;
  border-radius: 8px;
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex: 1;
  flex-grow: 1;
  height: 150px;
  font-size: 14px;
  line-height: 22px;
}

.u-links {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
}
.u-links-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
}

.u-footer {
  width: 100%;
  margin-top: 120px;
  padding: 80px 0;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.u-footer-header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.u-footer-title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.u-footer-desc {
  font-size: 14px;
  line-height: 20px;
}
.u-footer-icons {
  display: flex;
  gap: 4px;
  margin-bottom: -6px;
}
.u-footer-email {
  line-height: 20px;
  font-size: 14px;
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  .u-videos {
    flex-direction: column;
    gap: 16px;
  }
  .u-double-video {
    width: 100%;
  }
  .u-video {
    width: calc(100% - 32px);
    padding: 0 16px;
    border-radius: 4px;
  }
}
