.home-wrapper {
  /* opacity: 0;
  animation: fadeInContent 0.75s ease-out forwards;
  animation-delay: 600ms;
  will-change: opacity, transform; */
}
.splash-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
}
.splash-content-wrapper {
  display: flex;
  gap: 48px;
  margin-top: -40px;
}
.scroll-down-icon {
  opacity: 1;
  position: absolute;
  bottom: 104px;
  left: 50%;
  cursor: pointer;
  color: blue;
  /* transition: 0.3s all ease; */
  /* animation: fadeInScrollIcon 1s ease-out;
  animation-delay: 600ms;
  will-change: opacity, transform;
  animation-fill-mode: forwards; */
  transform: translateX(-50%);
}

@keyframes fadeInContent {
  0% {
    opacity: 0;
    transform: translateX(-4px) scale(0.999);
    filter: blur(1px);
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
    filter: blur(0);
  }
}
@keyframes fadeInScrollIcon {
  0% {
    opacity: 0;
    transform: translateY(8px) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

.splash-divider {
  height: auto;
  width: 1px;
  background: var(--color-text-primary);
}

/* ------------------- SPLASH LEFT ------------------- */
.splash-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 4px;
}
/* .splash-left h1 {
  white-space: nowrap;
  margin-bottom: 4px;
  animation: fadeInH1 0.95s ease-out;
  animation-delay: 300ms;
  will-change: opacity, transform;
  animation-fill-mode: forwards;
} */
@keyframes fadeInH1 {
  0% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
}
.splash-icons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}
.splash-icon {
  cursor: pointer;
  transition: 0.3s all ease;
  color: var(--color-text-primary);
}
.icon-linkedin:hover {
  color: #0077b5;
}
.icon-github:hover {
  color: #6cc644;
}
.icon-instagram:hover {
  color: #e4405f;
}
.icon-resume:hover {
  color: #c61cf4;
}
.splash-icon:active {
  opacity: 0.5;
}
.splash-info-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  margin-left: 5px;
}
.splash-info-item {
  opacity: 0.6;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.splash-info-text {
  font-size: 14px;
  line-height: 24px;
}
.sl-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 5px;
}
.sl-list-item {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 2px;
}
.sl-list-bullet {
  margin-left: 2px;
  font-family: var(--ff-default);
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
/* ------------------- SPLASH RIGHT ------------------- */
.splash-right {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  min-width: 0;
}
.sr-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.sr-item-wrapper {
  position: relative;
}
.sr-item-desc {
  line-height: 24px;
  white-space: normal;
}
.sr-item-dates {
  opacity: 0.5;
  font-size: 14px;
  line-height: 24px;
}
.blinker {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  left: -16px;
  flex-shrink: 0;
  top: 9px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
.blinker-everlaw {
  background: var(--color-everlaw);
  animation: pulse-animation-everlaw 2.5s infinite;
  animation-delay: 1s;
}
.blinker-festiplannr {
  background: rgba(0, 133, 255, 1);
  animation: pulse-animation-fp 2.5s infinite;
  animation-delay: 1s;
}
.blinker-wefunder {
  background: var(--color-wefunder);
  animation: pulse-animation-fp 2.5s infinite;
  animation-delay: 1s;
}
@keyframes pulse-animation-everlaw {
  0% {
    box-shadow: 0 0 0 0px rgba(98, 196, 28, 0.4);
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(98, 196, 28, 0);
    transform: scale(0.8);
  }
}
@keyframes pulse-animation-fp {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 133, 255, 0.7);
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 133, 255, 0);
    transform: scale(0.8);
  }
}

/* --------------------- SECTION --------------------- */
.section-wrapper {
  padding-left: 25%;
  padding-right: 25%;
  width: 50%;
}
.section-festiplannr {
  padding-top: 72px;
  padding-bottom: 56px;
  color: rgba(255, 255, 255, 0.85);
  position: relative;
  box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.3);
}
.section-festiplannr h2 {
  color: rgba(255, 255, 255, 0.85);
}
.section-festiplannr p {
  color: rgba(255, 255, 255, 0.85);
}
.section-video {
  width: 100%;
  height: 'auto';
  object-fit: 'cover';
  border-radius: 8px;
  margin-top: 32px;
  margin-bottom: 8px;
  box-shadow: 0px 2px 24px 2px rgba(255, 255, 255, 0.09);
}
.video-on-light {
  margin-top: 8px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
  box-shadow: 0px 4px 40px 2px rgba(0, 0, 0, 0.07);
}
.no-margin-top {
  margin-top: 0;
}
.section-everlaw {
  padding-top: 80px;
  padding-bottom: 100px;
  /* background: #F1F0EA; */
  position: relative;
  /* background: linear-gradient(0deg, #F8F8F8 0%, #f7eded 100%); */
}
.section-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section-title-link {
  cursor: pointer;
  transition: 0.3s all ease;
  text-decoration: none;
  width: fit-content;
}
.section-title-link h2 {
  border-bottom: 1px solid white;
  transition: 0.3s all ease;
}
.title-link-everlaw h2 {
  border-bottom: 1px solid black;
  transition: 0.3s all ease;
}
.section-title-link:hover h2 {
  color: rgba(0, 133, 255, 1);
  border-bottom: 1px solid rgba(0, 133, 255, 1);
}
.title-link-everlaw:hover h2 {
  color: var(--color-everlaw);
  border-bottom: 1px solid var(--color-everlaw);
}
.section-title-link:hover .link-festiplannr {
  transform: translateX(3px) translateY(-3px);
}
.section-title-link:hover .link-everlaw {
  transform: translateX(3px) translateY(-3px);
}
.section-content {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.section-content-item {
  padding-bottom: 34px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.section-data-wrapper {
  display: flex;
  flex-direction: row;
  gap: 48px;
  width: fit-content;
  margin-top: 16px;
}
.sd-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.sd-count {
  /* font-family: 'DM Serif Text', serif; */
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: 1px;
  color: rgb(164, 211, 255);
}
.sd-label {
  opacity: 0.6;
  line-height: 24px;
}

.header-text-festiplannr {
  color: white;
}

.p5-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(#090a11 33%, #03050c);
  will-change: transform;
}
.fallback-bg {
  background: linear-gradient(#090a11 33%, #03050c);
}
.fallback-bg-everlaw {
  background: #f1f0ea;
}
.p5-everlaw {
  background: #f1f0ea;
}

.back-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.back-to-top-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  justify-content: center;
  gap: 4px;
  transition: all 0.3s ease;
  margin-top: 96px;
  color: #a1a4af;
}
.back-to-top-button {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: 'Manrope', sans-serif;
  background: none;
  color: #a1a4af;
  transition: all 0.3s ease;
}
.back-to-top-wrapper:hover {
  color: var(--color-text-primary);
}
.back-to-top-wrapper:hover .back-to-top-button {
  color: var(--color-text-primary);
}
.back-to-top-wrapper:active {
  color: #ced1d9;
}
.back-to-top-wrapper:active .back-to-top-button {
  color: #ced1d9;
}

@media only screen and (min-width: 2400px) {
  .section-wrapper {
    padding-left: 35%;
    padding-right: 35%;
    width: 30%;
  }
}
@media only screen and (min-width: 1600px) {
  .section-wrapper {
    padding-left: 30%;
    padding-right: 30%;
    width: 40%;
  }
}
@media only screen and (max-width: 1400px) {
  .section-wrapper {
    padding-left: 20%;
    padding-right: 20%;
    width: 60%;
  }
}
@media only screen and (max-width: 1200px) {
  .section-wrapper {
    padding-left: 15%;
    padding-right: 15%;
    width: 70%;
  }
}
@media only screen and (max-width: 960px) {
  .section-wrapper {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }
  .splash-content-wrapper {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 0;
    flex-direction: column;
    gap: 32px;
  }
  .splash-left {
    align-items: flex-start;
  }
  .splash-info-items-wrapper {
    align-items: flex-start;
    margin-left: 0;
  }
  .sl-list {
    align-items: flex-start;
    margin-left: 0;
  }
  .sl-list-item {
    margin-left: 0;
  }
  .splash-divider {
    height: 1px;
    width: auto;
    background: var(--color-text-primary);
    opacity: 0.075;
  }
  .section-festiplannr {
    background: linear-gradient(#090a11 33%, #03050c);
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .section-everlaw {
    padding-top: 40px;
    background: #f1f0ea;
  }
}
@media only screen and (max-width: 480px) {
  .section-wrapper {
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 80px);
  }
  .splash-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .splash-content-wrapper {
    flex-direction: column;
    gap: 24px;
    padding-bottom: 48px;
    margin-left: 32px;
    margin-right: 32px;
  }
  .splash-icons-wrapper {
    margin-top: 0;
  }
  .scroll-down-icon {
    visibility: hidden;
  }
  .section-video {
    border-radius: 4px;
  }
  .section-festiplannr {
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.2);
  }
  .blinker {
    left: -14px;
  }
}
